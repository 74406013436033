<template>
	<a-spin :spinning="loading">
		<div class="my-md" v-if="wxmpQrCodePic != null">
			<div style="text-align:center;">
				<h1 style="margin-top: -5px;font-size: 20px;"><b>{{machineName}}</b></h1>
				<h2 style="margin-top: 0px;font-size: 20px;"><b>{{machineCode}}</b></h2>
			</div>
			<img :src="'data:image/png;base64,' + wxmpQrCodePic" :alt="machineName" />
		</div>
		<div class="my-md" v-else>
			<div style="text-align:center;">
				<h1 style="margin-top: -5px;font-size: 20px;"><b>{{machineName}}</b></h1>
				<h2 style="margin-top: 0px;font-size: 20px;"><b>{{machineCode}}</b></h2>
			</div>
			<h1>还未生成小程序二维码</h1>
		</div>
	</a-spin>
</template>

<script>
	import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
	import QRCode from 'qrcodejs2';
	import {AppConsts} from "../../../../abpPro/AppConsts";

	export default {
		name: "qrcode",
		mixins: [ModalComponentBase],
		components: {
			QRCode
		},
		data() {
			return {
				machineName: undefined,
				machineNumber: undefined,
				machineCode: undefined,
				wxmpQrCodePic: undefined,
				code: undefined,
			}
		},
		created() {
			this.fullData(); // 模态框必须,填充数据到data字段

		},
		mounted() {
		},
		methods: {

		}

	}
</script>

<style scoped>
	.qrcode {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
