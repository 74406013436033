<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="12">
						<a-button type="primary" @click="createOrEdit('create')" v-if="isGranted('audit_point_create')">
							<a-icon type="plus" />
							{{ l('Create') }}
						</a-button>
						<!-- <a-button type="primary" @click="createOrEditMachineSupplier('edit')">
							<a-icon type="edit"/>
							修改
						</a-button> -->
						<a-button type="danger" @click="batchdelete" v-if="isGranted('audit_point_delete')">
							<a-icon type="delete" />
							{{ l('Delete') }}
						</a-button>
						<!-- <a-button @click="qrcode">
						    <a-icon type="qrcode"/>
						    查看二维码
						</a-button> -->
						<a-button @click="printQrCode" v-if="isGranted('audit_point_qrcode')">
							<a-icon type="printer" />
							{{ l('qrcode') }}
						</a-button>
					</a-col>

					<a-col :span="12" class="d-flex justify-content-end">
						<a-input-search :placeholder="l('please_enter_a_name_or_number_to_search')"
							v-model.trim="filterText" enterButton style="width: 200px;margin-left: 10px;"
							@search="filteronchange"></a-input-search>
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
						:row-selection="{ selectedRowKeys: selectedRowKey, onSelect: onSelect, onSelectAll: SelectAll, columnWidth: '50px' }"
						:rowKey="(record) => record.id" :scroll="{ x: 800, y: 610 }" :pagination="false" size="small">
						<span slot="enable" slot-scope="text">
							<span v-if="text"><a-icon type='check' style="color: #1890ff;" /></span>
							<span v-else><a-icon type='close' /></span>
						</span>
						<span slot="qrcode" slot-scope="text,record">
							<a-icon type="qrcode" @click="qrcode(record)" />
						</span>

						<span slot="wxmpQrCodePic" slot-scope="text,record">
							<span v-if="record.wxmpQrCodePic == null || record.wxmpQrCodePic == ''"><a @click="generateWXMPQrCodePic(record)">生成</a></span>
							<span v-else><a-icon type="wechat" @click="wxmpQrCodePic(record)" /></span>
						</span>

						<span slot="action" slot-scope="text,record">
							<a @click.stop="createOrEditMachineSupplier(record)" v-if="isGranted('audit_point_edit')">
								<a-icon type="edit" />{{ l('Edit') }}
							</a>
							<font v-if="isGranted('audit_point_delete') && isGranted('audit_point_edit')"> | </font>
							<a @click.stop="deletetable(record)" v-if="isGranted('audit_point_delete')">
								<a-icon type="delete" />{{ l('Delete') }}
							</a>
						</span>
					</a-table>
				</a-spin>
				<a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
					:show-total="total => `共有 ${totalItems} 条`" :defaultPageSize="request.maxResultCount"
					:pageSizeOptions="pageSizeOptions" @change="onChangePage" @showSizeChange="showSizeChange" />
				<!-- <a-pagination
				        class="pagination"
				        :total="totalItems"
				        showSizeChanger
				        showQuickJumper
				        :showTotal="showTotalFun"
				        @change="onChangePage"
				        @showSizeChange="showSizeChange"/> -->
			</a-card>
		</a-config-provider>
		<div style="display:none">
			<div id="qrcodeDiv">
				<div style="display:table-cell;float:left; width:426px; height:213px;border: 1px solid black; margin:15px;margin-top:30px;text-align:center;"
					v-for="item in selectedRowList" :key="item.id">
					<div
						style="float: left;width: 200px;text-align: center;height: 213px;display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center;-webkit-justify-content: center;justify-content: center;">
						<ul>
							<li style="list-style: none;font-size: 18px;width: 200px;">{{ item.name }}</li>
							<li style="list-style: none;font-size: 18px;width: 200px;">{{ item.code }}</li>
						</ul>
					</div>

					<div :id="'qrcode' + AppConsts + '/' + 'etag' + '/' + item.id"
						style="float:right;margin-right: 20px;margin-top: 22px;" v-if="item.qrCode == undefined"></div>
					<div :id="'qrcode' + AppConsts + '/' + 'etag' + '/' + item.qrCode"
						style="float:right;margin-right: 20px;margin-top: 22px;" v-if="item.qrCode != undefined"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { AuditPointServiceProxy } from '../../../shared/service-proxies';
import { ModalHelper } from '@/shared/helpers';
import CreatErrorCode from "./new-audit-point/new-audit-point.vue";
import QRCode from "qrcodejs2";
import qrcode from "./qrcode/qrcode.vue";
import wxmpQrCodePic from "./wxmpQrCodePic/wxmpQrCodePic.vue";
import { AppConsts } from "../../../abpPro/AppConsts";

let _this;
export default {
	name: "audit-point",
	mixins: [AppComponentBase],
	components: {
		qrcode,
		wxmpQrCodePic
	},
	created() {
		_this = this;
		this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData()
	},
	data() {
		return {
			zh_CN,
			isEdit: false,
			isLook: false,

			filterText: undefined,
			totalItems: 0,//总数
			// 当前页码
			pageNumber: 1,
			// 共多少页
			totalPages: 1,
			// 条数显示范围
			pagerange: [1, 1],
			// 显示条数
			pageSizeOptions: ["10", "20", "50", "100", "500"],
			request: { maxResultCount: 20, skipCount: 0 },

			tableData: [],
			columns: [
				{ title: this.l('Name'), width: 350, dataIndex: 'name', align: 'left', ellipsis: true },
				{ title: this.l('qrcode'), width: 80, dataIndex: 'qrcode', align: 'center', scopedSlots: { customRender: "qrcode" } },
				{ title: this.l('Code'), width: 150, dataIndex: 'code', align: 'center', },
				{ title: this.l('auditpoint.respid'), width: 150, dataIndex: 'respName', align: 'center', ellipsis: true },
				{ title: this.l('auditpoint.enable'), width: 60, dataIndex: 'enable', align: 'center', scopedSlots: { customRender: "enable" } },
				{ title: this.l('Remark'), dataIndex: 'remark', align: 'left', ellipsis: true },
				{ title: this.l('微信二维码'), width: 80, dataIndex: 'wxmpQrCodePic', align: 'center', scopedSlots: { customRender: "wxmpQrCodePic" } },
				{ title: this.l('Actions'), width: 150, dataIndex: 'action', align: 'center', scopedSlots: { customRender: "action" }, fixed: 'right' },
			],
			selectedRowKey: [], // 选择的数据Id
			selectedRowList: [],
			selectedRow: [],//选择的整行数据
			isLoading: false,//加载中
			children: [],
			name: [],
			number: [],
			respName: [],
			AppConsts: AppConsts.appBaseUrl,
		}
	},
	methods: {

		//删除
		generateWXMPQrCodePic(record) {
			this.AuditPointServiceProxy.generateWXMPQrCodePic(record.id).then(res => {
				this.getData();
				this.$notification["success"]({
					message: this.l("生成成功"),
				});
			})
		},

		//获取数据
		getData() {
			this.selectedRowKey = [];
			this.selectedRowList = [];
			this.isLoading = true;
			this.AuditPointServiceProxy.getPaged(
				this.filterText ? this.filterText : undefined,
				undefined,
				this.request.maxResultCount,
				this.request.skipCount
			).finally(() => {
				this.isLoading = false;
			}).then(res => {
				console.log(res);
				this.tableData = res.items;

				this.totalItems = res.totalCount;
				this.totalPages = Math.ceil(
					res.totalCount / this.request.maxResultCount
				);
				this.pagerange = [
					(this.pageNumber - 1) * this.request.maxResultCount + 1,
					this.pageNumber * this.request.maxResultCount,
				];
			})
		},
		//新增
		createOrEdit(cases) {
			ModalHelper.create(CreatErrorCode, {
				isEdit: false,
				id: undefined
			}, { width: 1000 }).subscribe(res => {
				console.log(res)
				if (res) {
					this.getData();
					this.selectedRowKey = [];
					this.selectedRowList = [];
				}
			})
		},
		//编辑
		createOrEditMachineSupplier(record) {
			ModalHelper.create(CreatErrorCode, {
				isEdit: true,
				id: record.id,
				respName: this.respName
			}, { width: 1000 }).subscribe(res => {
				console.log(res)
				this.getData();
				this.selectedRowKey = [];
				this.selectedRowList = [];
			})
		},
		//批量删除事件
		batchdelete() {
			if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
				abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
				return;
			}
			if (this.children[0] != undefined) {
				abp.message.warn(this.l("which_cannot_be_deleted"));
				return;
			}
			this.$confirm(
				{
					title: this.l("ConfirmInformation"),
					content: this.l("the_selected_option"),
					cancelText: this.l('Cancel'),
					okText: this.l('Ok'),
					onOk: () => {
						this.AuditPointServiceProxy.batchDelete(this.selectedRowKey).then(res => {
							this.getData();
							this.$notification["success"]({
								message: this.l("SuccessfullyDeleted"),
							});
						})
					}
				})
		},
		//删除
		deletetable(record) {
			this.$confirm(
				{
					title: this.l("ConfirmInformation"),
					content: this.l("the_selected_option"),
					cancelText: this.l('Cancel'),
					okText: this.l('Ok'),
					onOk: () => {
						this.AuditPointServiceProxy.delete(record.id).then(res => {
							this.getData();
							this.$notification["success"]({
								message: this.l("SuccessfullyDeleted"),
							});
						})
					}
				})
		},
		//行选择
		customRow: (record) => {
			return {
				on: {
					click: () => {
						_this.onSelect(record)
					}
				}
			}

		},
		//多选
		SelectAll(record, rows, crows) {
			if (record) {
				//更改表格数据勾选状态
				this.tableData.map(item => {
					item.checked = true;
				});
				rows.forEach((item, index) => {
					//判断是否存在
					let u = this.selectedRowKey.find(t => t == item.id);
					if (!u) {
						this.selectedRowKey.push(item.id);
						this.selectedRowList.push(item);
					}
				});
			} else {
				//更改表格数据勾选状态
				this.tableData.forEach((item, index) => {
					item.checked = false;
					this.selectedRowKey = [];
					this.selectedRowList = [];
				});
			}
		},
		// 重写复选框点击事件(不可删除)
		onSelect(record) {
			//debugger;
			console.log(record);
			record.checked = !record.checked;
			if (record.checked) {
				this.selectedRowKey.push(record.id);
				this.children.push(record.children);
				this.selectedRowList.push(record);
				this.name = record.name;
				this.number = record.code;
				this.respName = record.respName;
			} else {
				this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
				this.children = _.difference(_this.children, [record.children]);
				this.selectedRowList = _.difference(_this.selectedRowList, [record]);
			}
		},
		//搜索框点击事件
		filteronchange() {
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		//分页
		onChangePage(page, pageSize) {
			this.selectedRowKey = [];
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		//正常分页展示形式，显示（共有 {0} 条）
		// showTotalFun() {
		// 	console.log(this.totalItems);
		//     return this.l(
		//         "GridFooterDisplayText",
		//         this.totalItems
		//     );
		// },
		showSizeChange(current, size) {
			this.selectedRowKey = [];
			this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
			this.getData();
		},
		//二维码
		qrcode(record) {
			// if (this.selectedRowKey.length > 1) {
			//     abp.message.info('请选择一条数据查看')
			//     return;
			// }
			console.log(record);
			ModalHelper.create(qrcode,
				{ machineName: record.name, machineNumber: record.id, machineCode: record.code, qrCode: record.qrCode },
				{ width: 248, height: 142 })
				.subscribe(res => {
					this.selectedRowKey = [];
					this.selectedRowList = [];
				})
		},

		//二维码
		wxmpQrCodePic(record) {
			// if (this.selectedRowKey.length > 1) {
			//     abp.message.info('请选择一条数据查看')
			//     return;
			// }
			console.log(record);
			ModalHelper.create(wxmpQrCodePic,
				{ machineName: record.name, machineNumber: record.id, machineCode: record.code, wxmpQrCodePic: record.wxmpQrCodePic },
				{ width: 500, height: 400 })
				.subscribe(res => {
					this.selectedRowKey = [];
					this.selectedRowList = [];
				})
		},

		//批量打印二维码
		printQrCode() {
			if (_this.selectedRowList.length == 0) {
				abp.message.info('请选择一条数据进行操作');
				return;
			}

			//创建二维码
			this.creatQrCode();

			// _this.handlePrint();

			setTimeout(function () {
				//打印二维码
				_this.handlePrint();
			}, 1000);
		},
		//创建二维码
		creatQrCode() {
			for (let i = 0; i < _this.selectedRowList.length; i++) {
				let divId = _this.selectedRowList[i].id;
				if (_this.selectedRowList[i].qrCode == undefined) {
					let code = AppConsts.appBaseUrl + "/" + "etag" + "/" + divId;
					let div = document.getElementById('qrcode' + code);
					new QRCode(div, {
						width: 170,
						height: 170, // 高度
						text: "" + code + "", // 二维码内容
						correctLevel: QRCode.CorrectLevel.H
					})
				} else {
					let divcode = _this.selectedRowList[i].qrCode;
					let code = AppConsts.appBaseUrl + "/" + "etag" + "/" + divcode;
					let div = document.getElementById('qrcode' + code);
					new QRCode(div, {
						width: 170,
						height: 170, // 高度
						text: "" + code + "", // 二维码内容
						correctLevel: QRCode.CorrectLevel.H
					})
				}
			}
		},
		handlePrint() {
			//新打开一个空窗口
			let iLeft = (window.screen.width - 10 - 900) / 2; //获得窗口的水平位置;
			let newWin = window.open("", "_blank", "height=900, width=900,top=0, left=" + iLeft + ", scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes");
			let imageToPrint = document.getElementById("qrcodeDiv"); //获取需要打印的内容
			newWin.document.write(imageToPrint.outerHTML); //将需要打印的内容添加进新的窗口
			newWin.document.close(); //在IE浏览器中使用必须添加这一句
			newWin.focus(); //在IE浏览器中使用必须添加这一句
			setTimeout(function () {
				newWin.print(); //打印
				newWin.close(); //关闭窗口
			}, 100);
			//清空二维码信息
			for (let i = 0; i < this.selectedRowList.length; i++) {
				if (_this.selectedRowList[i].qrCode == undefined) {
					let divId = this.selectedRowList[i].id;
					let code = AppConsts.appBaseUrl + "/" + "etag" + "/" + divId;
					let div = document.getElementById('qrcode' + code);
					div.innerHTML = '';
				} else {
					let divcode = _this.selectedRowList[i].qrCode;
					let code = AppConsts.appBaseUrl + "/" + "etag" + "/" + divcode;
					let div = document.getElementById('qrcode' + code);
					div.innerHTML = '';
				}
			}
		},


	}
}
</script>

<style>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
