<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">

                <a-row>
                    <a-col :span="12">
                        <a-row>
                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :label="l('pid')"
                                    class="body">
                                    <a-tree-select v-model="pId" style="width: 100%" :tree-data="treeDataList"
                                        :replace-fields="treeReplaceFields"
                                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
                                        :dropdownMatchSelectWidth="false"
                                        :placeholder="l('please_select_the_superior_node')" allow-clear
                                        tree-default-expand-all>
                                    </a-tree-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="18">
                                <a-form-item required :label-col="{ span: 8 }" :wrapper-col="{ span: 15 }"
                                    :label="l('Name')" class="body">
                                    <a-input v-model="entity.name" class="input" :placeholder="l('Name')"></a-input>
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" class="body">
                                    <a-input v-model="entity.code" class="input" :placeholder="l('Code')"></a-input>
                                </a-form-item>
                            </a-col>

                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }"
                                    :label="l('auditpoint.respid')" class="body">
                                    <a-input v-model="respName" :placeholder="l('Please_select_the_person_responsible')"
                                        allow-clear style="width: 100%;" @click="respNameSelect"></a-input>
                                </a-form-item>
                            </a-col>

                            <a-col :span="6">
                                <a-form-item :label="l('field_certification')" class="body">
                                </a-form-item>
                            </a-col>
                            <a-col :span="6" style="margin-top: 8px">
                                <!--<a-switch :checked-children="l('Yes')" :un-checked-children="l('No')"
                                          v-model="mustQRCode" />-->
                                <a-checkbox v-model:checked="checked" v-model="mustQRCode">{{l("qrcode")}}</a-checkbox>
                            </a-col>
                            <a-col :span="6" style="margin-top: 8px">
                                <!--<a-switch :checked-children="l('Yes')" :un-checked-children="l('No')"
                                          v-model="mustNFC"/>-->
                                <a-checkbox v-model:checked="checked" v-model="mustNFC">{{l("nfc")}}</a-checkbox>
                            </a-col>
                            <a-col :span="6" style="margin-top: 8px">
                            </a-col>
                            <a-col :span="24" v-if="mustQRCode">
                                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :label="l('qrcode')"
                                    class="body">
                                    <a-input v-model="qrcode" class="input"></a-input>
                                </a-form-item>
                            </a-col>
                            <a-col :span="24" v-if="mustNFC">
                                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }"
                                    :label="l('auditpoint.nfcno')" class="body">
                                    <a-input v-model="entity.nfcNo" class="input"></a-input>
                                </a-form-item>
                            </a-col>
                            <a-col :span="24" v-if="mustNFC">
                                <a-form-item required :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }"
                                    :label="l('auditpoint.nfcid')" class="body">
                                    <a-input v-model="entity.nfcid" class="input"></a-input>
                                </a-form-item>
                            </a-col>

                            <a-col :span="24" style="margin: 5px 0px;">
                                <a-col :span="6">
                                    <a-form-item :label="l('Image')">

                                    </a-form-item>
                                </a-col>
                                <a-col :span="11">
                                    <a-upload style="word-wrap:break-word; width: 100px;height:100px"
                                        :action="uploadPictureUrl" list-type="picture-card" :fileList="Picturelist"
                                        accept="image/*" :headers="picUploadHeaders" :beforeUpload="beforeUpload"
                                        :remove="removePic" :multiple="true" @preview="handlePreview"
                                        @change="handleChange">
                                        <div v-if="Picturelist.length < 1">
                                            <a-icon type="plus" />
                                            <!-- {{ l('UploadProfilePicture') }} -->
                                            <div class="ant-upload-text">{{l('Upload_an_image')}}</div>
                                        </div>
                                    </a-upload>
									<a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
									    <img alt="example" style="width: 100%" :src="previewImage" />
									</a-modal>
                                </a-col>
                            </a-col>

                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 9 }"
                                    :label="l('auditpoint.enable')" class="body">
                                    <a-switch style="right: 120px;" :checked-children="l('Yes')"
                                        :un-checked-children="l('No')" v-model="enable" />
                                </a-form-item>
                            </a-col>

                            <a-col :span="24">
                                <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :label="l('Remark')">
                                    <a-input v-model="entity.remark"></a-input>
                                </a-form-item>

                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="12">
                        <a-row>
                            <a-col :span="24">

                                <a-col :span="24" style="margin-bottom: -40px;">
                                    <editor v-model="entity.description" disabled="false" @onChange="editorOnChang">
                                    </editor>
                                </a-col>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { AuditPointServiceProxy, IssueTypeEditDto, EmployeeServiceProxy } from '../../../../shared/service-proxies';
    import { ModalHelper } from '@/shared/helpers';
    import PersonnelSelection from "../../Personnel-selection/Personnel-selection.vue";
    import Editor from "../../../../components/point_editor/editor";
    import { AppConsts } from "../../../../abpPro/AppConsts";
    import ImageUtils from "../../../../shared/utils/image-utils";

    let _this;
    export default {
        name: 'new-audit-point',
        mixins: [ModalComponentBase],
        components: {
            Editor,
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.AuditPointServiceProxy = new AuditPointServiceProxy(this.$apiUrl, this.$api);
            this.EmployeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getTreeData();
            this.getData();
            this.getWorkProcessData()
        },
        data() {
            return {
                zh_CN,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: {},
                a: new IssueTypeEditDto(),
                pId: undefined,
                respId: undefined,
                respName: undefined,
                enable: true,
                treeDataList: [],
                treeReplaceFields: {
                    title: 'name',
                    key: 'id',
                    value: 'id'
                },
                workProcessClass: undefined,
                workProcessData: [],
                aname: undefined,
                mustQRCode: false,
                mustNFC: false,
                qrcode: undefined,
                isclick: true,

                // 上传的接口地址
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
				Picture: '',
                Picturelist: [],
                //图片的请求头信息
                picUploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken(),
                    type: "photo"
                },
                previewImage: '',
                previewVisible: false,
            }
        },
        methods: {
			//预览
			handleCancel() {
			    this.previewVisible = false;
			},
            async handlePreview(file) {
				console.log(1)
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleChange({ file, fileList }) {
                if (file.status === 'done') {
                    this.Picture = file.response.result.fileName;
                }

                if (file.status === 'removed') {
                    if (file.response == undefined) {
                        var str = file.name;
                        this.Picture = this.Picture.split(str).join('');
                    } else {
                        var str = file.response.result.fileName + ',';
                        this.Picture = this.Picture.split(str).join('');
                    }
                }
				console.log(this.Picture)
                this.Picturelist = fileList;
            },

            //图片上传
            beforeUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "image/gif" ||
                    file.type === "image/bmp";
                if (!isJPG) {
                    abp.message.error(this.l("请上传正确格式的图片文件"));
                }
                const isLtXM = file.size / 1024 / 1024 < 5;
                if (!isLtXM) {
                    abp.message.error(
                        this.l(
                            "ProfilePicture_Warn_SizeLimit",
                            2
                        )
                    );
                }
                return isJPG && isLtXM;
            },

            //删除
            removePic(file) {
                return new Promise((resolve, reject) => {
                    this.$confirm({
                        title: this.l("ConfirmInformation"),
                        content: this.l("the_selected_option"),
                        cancelText: this.l('Cancel'),
                        okText: this.l('Ok'),
                        onOk() {
                            resolve(true)
                        },
                        onCancel() {
                            reject(false)
                        },
                    })

                })

            },

            editorOnChang(e) {
                this.entity.description = e;
            },

            getData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.entity = res;
                    if (this.entity.name == null) {

                    } else {
                        this.enable = this.entity.enable;
                    }
                    if (this.entity.pId == 0) {
                        this.pId = undefined;
                    } else if (this.entity.pId != 0) {
                        this.pId = res.pId;
                    }
                    if (this.entity.respId == 0) {
                        this.respId = undefined;
                    } else if (this.entity.respId != 0) {
                        this.respId = res.respId;
                    }
                    this.respName = res.respName;
                    this.mustQRCode = res.mustQRCode;
                    this.mustNFC = res.mustNFC;
                    this.qrcode = res.qrCode;
					if(res.picture) {
						this.Picturelist.push(
						           {
						               uid: res.picture,
						               name: res.picture,
						               status: 'done',
						               url: res.realPath+res.picture,
						           }
						       )
					}
					console.log(this.Picturelist)
                })
            },
            getTreeData() {
                this.isLoading = true;
                this.AuditPointServiceProxy.getAllData(
                )
                    .finally(() => {
                        this.isLoading = false;
                    })
                    .then((res) => {
                        console.log(res);
                        this.treeDataList = JSON.parse(JSON.stringify(res));
                    })
            },
            //获取负责人
            getWorkProcessData() {
                this.isLoading = true;
                this.EmployeeServiceProxy.getAllEmp(
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.workProcessData = res;
                })
            },
            workProcessClassChange(value) {
                console.log(value.key);
                this.entity.respId = value.key;
                // this.getData();
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            save() {
                if (!this.entity.name) {
                    abp.message.info(this.l("auditpoint_name"));
                    return;
                }
                if (this.mustNFC == true && (this.entity.nfcid == null || this.entity.nfcid == "")) {
                    abp.message.info(this.l("point_tip"));
                    return;
                }
                if (this.isEdit == true) {
                    if (this.pId == this.id) {
                        abp.message.info(this.l('You_cannot_choose_yourself_as_the_superior_node'));
                        return;
                    }
                }
                if (this.pId == undefined) {
                    this.entity.pId = 0;
                } else {
                    this.entity.pId = this.pId;
                }
                this.entity.respId = this.respId;
                this.entity.enable = this.enable;
                this.entity.mustQRCode = this.mustQRCode;
                this.entity.mustNFC = this.mustNFC;
                this.entity.qrCode = this.qrcode;
                this.entity.picture = this.Picture;
                var auditPointEdit = { auditPointEdit: this.entity };
                console.log(auditPointEdit);
                if (this.isclick) {
                    this.isclick = false;
                    this.AuditPointServiceProxy.createOrUpdate(
                        auditPointEdit
                    ).finally(() => {
                        this.close()
                    }).then(res => {
                        this.isclick = true;
                        this.success(true)
                    })
                }

            },

            respNameSelect() {
                ModalHelper.create(PersonnelSelection, {
                    respId: this.respId,
                    isSelect: true,
                    userType: 1
                }, {
                    width: 800
                }).subscribe(res => {
                    if (res) {
                        this.respName = res[0].realName;
                        this.respId = res[0].userId;
                    }
                })
            },

        }
    }
</script>

<style>
    .body {
        margin-bottom: 10px;
    }

    .input {
        width: 100%;
    }
</style>