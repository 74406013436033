<template>
	<a-spin :spinning="loading">
		<div class="my-md">
			<div style="text-align:center;">
				<h1 style="margin-top: -5px;font-size: 20px;"><b>{{machineName}}</b></h1>
				<h2 style="margin-top: 0px;font-size: 20px;"><b>{{machineCode}}</b></h2>
			</div>
			<div class="qrcode" ref="qrCodeUrl" style="text-align: left;"></div>
		</div>
	</a-spin>
</template>

<script>
	import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
	import QRCode from 'qrcodejs2';
	import {AppConsts} from "../../../../abpPro/AppConsts";

	export default {
		name: "qrcode",
		mixins: [ModalComponentBase],
		components: {
			QRCode
		},
		data() {
			return {
				machineName: undefined,
				machineNumber: undefined,
				machineCode: undefined,
				qrCode: undefined,
				code: undefined,
			}
		},
		created() {
			this.fullData(); // 模态框必须,填充数据到data字段

			console.log(this.qrCode);

		},
		mounted() {
			this.creatQrCode();
		},
		methods: {

			creatQrCode() {
				if(this.qrCode==undefined) {
					this.code = AppConsts.appBaseUrl+"/"+"etag"+"/"+this.machineNumber;
					var qrcode = new QRCode(this.$refs.qrCodeUrl, {
						text: ""+this.code+"", // 需要转换为二维码的内容
						width: 200,
						height: 200,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
				}else {
					this.code = AppConsts.appBaseUrl+"/"+"etag"+"/"+this.qrCode;
					console.log(this.code);
					var qrcode = new QRCode(this.$refs.qrCodeUrl, {
						text: ""+this.code+"", // 需要转换为二维码的内容
						width: 200,
						height: 200,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
				}
				
			},
		}

	}
</script>

<style scoped>
	.qrcode {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
